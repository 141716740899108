import React from 'react';
import styled from 'styled-components';
import { Layout, SEO, Separator, PolicyThreeImages } from 'components';

export default () => (
  <Layout>
    <SEO
      pageSpecificTitle="Women In Sport Policy"
      pageSpecificDescription="Learn more about Sport Ireland's 'Women In Sport Policy'."
      keywords={[
        'sport ireland',
        'love sport',
        'women in sport',
        'Roisin Upton',
        'Róisín Upton',
        'Fiona Coghlan',
        'Emma Johnstone',
        'olympics',
        'special olympics'
      ]}
    />
    <Separator>
      <h4>Women In Sport Policy</h4>
    </Separator>
    <PolicyThreeImages />
    <IFrameWrapper>
      <iframe
        title="Women In Sport Policy"
        style={{
          border: 'none',
          width: '100%',
          height: '692px'
        }}
        src="//e.issuu.com/embed.html#34254485/68245675"
        allowFullScreen
      />
    </IFrameWrapper>
    <Separator>
      <h4>Contact Us</h4>
    </Separator>
  </Layout>
);

/* prettier-ignore */
const IFrameWrapper = styled.section`
  background-color: rgba(0, 0, 0, 0.15);
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 1.15rem;
  letter-spacing: 1px;
`;
